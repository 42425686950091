import React from "react";
import { useOverrides, Override } from "@quarkly/components";
import { Image, Box, Text, Link, Section } from "@quarkly/widgets";
const defaultProps = {
	"padding": "54px 0 30px 0",
	"quarkly-title": "Footer",
	"lg-padding": "30px 0 20px 0"
};
const overrides = {
	"box": {
		"kind": "Box",
		"props": {
			"empty-min-height": "64px",
			"empty-border-width": "1px",
			"empty-border-style": "solid",
			"width": "25%",
			"md-width": "50%",
			"sm-margin": "0px 0px 16px 0px",
			"empty-min-width": "64px",
			"empty-border-color": "LightGray",
			"padding": "16px 16px 16px 16px",
			"md-align-items": "flex-start",
			"md-display": "flex",
			"md-justify-content": "center",
			"sm-padding": "16px 16px 16px 0px"
		}
	},
	"image": {
		"kind": "Image",
		"props": {
			"src": "https://uploads.quarkly.io/651c006edbe26f0020fbd967/images/5334343logo.png?v=2023-10-03T11:59:56.944Z",
			"width": "248px",
			"height": "auto",
			"max-width": "100%"
		}
	},
	"box1": {
		"kind": "Box",
		"props": {
			"width": "25%",
			"flex-direction": "column",
			"md-width": "50%",
			"sm-padding": "16px 16px 16px 0px",
			"empty-border-style": "solid",
			"empty-min-height": "64px",
			"empty-border-width": "1px",
			"empty-border-color": "LightGray",
			"padding": "16px 16px 16px 16px",
			"display": "flex",
			"empty-min-width": "64px"
		}
	},
	"text": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 32px 0px",
			"font": "--headline4",
			"color": "--dark",
			"lg-font": "600 24px/1.3 \"Manrope\", sans-serif",
			"children": "Links"
		}
	},
	"link": {
		"kind": "Link",
		"props": {
			"margin": "0px 0px 16px 0px",
			"lg-font": "normal 400 16px/1.5 \"Manrope\", sans-serif",
			"text-decoration-line": "initial",
			"color": "--dark",
			"font": "--lead",
			"children": "Home",
			"href": "/index"
		}
	},
	"link1": {
		"kind": "Link",
		"props": {
			"lg-font": "normal 400 16px/1.5 \"Manrope\", sans-serif",
			"text-decoration-line": "initial",
			"color": "--dark",
			"font": "--lead",
			"margin": "0px 0px 16px 0px",
			"href": "/about",
			"children": "About"
		}
	},
	"link2": {
		"kind": "Link",
		"props": {
			"color": "--dark",
			"font": "--lead",
			"margin": "0px 0px 16px 0px",
			"lg-font": "normal 400 16px/1.5 \"Manrope\", sans-serif",
			"text-decoration-line": "initial",
			"children": "Contacts",
			"href": "/contact-us"
		}
	},
	"link3": {
		"kind": "Link",
		"props": {
			"text-decoration-line": "initial",
			"color": "--dark",
			"font": "--lead",
			"margin": "0px 0px 16px 0px",
			"lg-font": "normal 400 16px/1.5 \"Manrope\", sans-serif",
			"children": "Privacy Policy",
			"href": "/privacy-policy"
		}
	},
	"box2": {
		"kind": "Box",
		"props": {
			"empty-border-width": "1px",
			"padding": "16px 16px 16px 16px",
			"display": "flex",
			"md-width": "50%",
			"sm-padding": "16px 16px 16px 0px",
			"empty-min-width": "64px",
			"empty-min-height": "64px",
			"empty-border-style": "solid",
			"empty-border-color": "LightGray",
			"width": "25%",
			"flex-direction": "column"
		}
	},
	"box3": {
		"kind": "Box",
		"props": {
			"empty-min-height": "64px",
			"empty-border-width": "1px",
			"empty-border-color": "LightGray",
			"padding": "16px 16px 16px 16px",
			"display": "flex",
			"flex-direction": "column",
			"sm-padding": "16px 16px 16px 0px",
			"empty-min-width": "64px",
			"empty-border-style": "solid",
			"width": "25%",
			"md-width": "50%"
		}
	},
	"text1": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 32px 0px",
			"font": "--headline4",
			"color": "--dark",
			"lg-font": "600 24px/1.3 \"Manrope\", sans-serif",
			"children": "Contacts"
		}
	},
	"link4": {
		"kind": "Link",
		"props": {
			"text-decoration-line": "initial",
			"color": "--dark",
			"font": "--lead",
			"margin": "0px 0px 16px 0px",
			"lg-font": "normal 400 16px/1.5 \"Manrope\", sans-serif",
			"children": "+91 98918 02213"
		}
	},
	"link5": {
		"kind": "Link",
		"props": {
			"margin": "0px 0px 16px 0px",
			"lg-font": "normal 400 16px/1.5 \"Manrope\", sans-serif",
			"text-decoration-line": "initial",
			"color": "--dark",
			"font": "--lead",
			"children": "info@asvata-olare.net"
		}
	},
	"link6": {
		"kind": "Link",
		"props": {
			"lg-font": "normal 400 16px/1.5 \"Manrope\", sans-serif",
			"text-decoration-line": "initial",
			"color": "--dark",
			"font": "--lead",
			"margin": "0px 0px 16px 0px",
			"children": "2, Ground Floor and First Floor, Pocket 7 Ashish Plaza, Sector 12 Dwarka, Delhi 110075"
		}
	}
};

const Footer = props => {
	const {
		override,
		children,
		rest
	} = useOverrides(props, overrides, defaultProps);
	return <Section {...rest}>
		<Override slot="SectionContent" flex-direction="row" md-flex-wrap="wrap" />
		<Box {...override("box")}>
			<Image {...override("image")} />
		</Box>
		<Box {...override("box1")}>
			<Text {...override("text")} />
			<Link {...override("link")} />
			<Link {...override("link1")} />
			<Link {...override("link2")} />
			<Link {...override("link3")} />
		</Box>
		<Box {...override("box2")}>
			 
		</Box>
		<Box {...override("box3")}>
			<Text {...override("text1")} />
			<Link {...override("link4")} />
			<Link {...override("link5")} />
			<Link {...override("link6")} />
		</Box>
		{children}
	</Section>;
};

Object.assign(Footer, { ...Section,
	defaultProps,
	overrides
});
export default Footer;