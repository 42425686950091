import React from "react";
import { useOverrides, Override } from "@quarkly/components";
import { Image, Link, Box, Section } from "@quarkly/widgets";
import QuarklycommunityKitMobileSidePanel from "./QuarklycommunityKitMobileSidePanel";
const defaultProps = {
	"padding": "18px 0 18px 0",
	"quarkly-title": "Header"
};
const overrides = {
	"image": {
		"kind": "Image",
		"props": {
			"max-height": "80px",
			"src": "https://uploads.quarkly.io/651c006edbe26f0020fbd967/images/5334343logo.png?v=2023-10-03T11:59:56.944Z",
			"width": "25%",
			"height": "auto",
			"lg-max-width": "150px",
			"sm-width": "50%"
		}
	},
	"quarklycommunityKitMobileSidePanel": {
		"kind": "QuarklycommunityKitMobileSidePanel",
		"props": {
			"width": "50%"
		}
	},
	"quarklycommunityKitMobileSidePanelOverride": {
		"kind": "Override",
		"props": {
			"slot": "Button Text",
			"lg-display": "none"
		}
	},
	"quarklycommunityKitMobileSidePanelOverride1": {
		"kind": "Override",
		"props": {
			"slot": "Button Icon",
			"size": "32px"
		}
	},
	"box": {
		"kind": "Box",
		"props": {
			"display": "flex",
			"justify-content": "center",
			"align-items": "center",
			"md-flex-direction": "column"
		}
	},
	"link": {
		"kind": "Link",
		"props": {
			"lg-margin": "0px 6px 0px 6px",
			"text-decoration-line": "initial",
			"color": "--dark",
			"font": "--lead",
			"padding": "6px 6px 6px 6px",
			"margin": "0px 18px 0px 18px",
			"md-margin": "0px 18px 10px 18px",
			"children": "Home",
			"href": "/index"
		}
	},
	"link1": {
		"kind": "Link",
		"props": {
			"lg-margin": "0px 6px 0px 6px",
			"href": "#",
			"text-decoration-line": "initial",
			"color": "--dark",
			"font": "--lead",
			"padding": "6px 6px 6px 6px",
			"margin": "0px 18px 0px 18px",
			"md-margin": "0px 18px 10px 18px",
			"children": "Menu"
		}
	},
	"link2": {
		"kind": "Link",
		"props": {
			"font": "--lead",
			"padding": "6px 6px 6px 6px",
			"margin": "0px 18px 0px 18px",
			"md-margin": "0px 18px 10px 18px",
			"lg-margin": "0px 6px 0px 6px",
			"text-decoration-line": "initial",
			"color": "--dark",
			"children": "About",
			"href": "/about"
		}
	},
	"link3": {
		"kind": "Link",
		"props": {
			"padding": "6px 6px 6px 6px",
			"margin": "0px 18px 0px 18px",
			"lg-margin": "0px 6px 0px 6px",
			"text-decoration-line": "initial",
			"color": "--dark",
			"font": "--lead",
			"children": "Contact",
			"href": "/contact-us"
		}
	}
};

const Header = props => {
	const {
		override,
		children,
		rest
	} = useOverrides(props, overrides, defaultProps);
	return <Section {...rest}>
		<Override slot="SectionContent" justify-content="space-around" flex-direction="row" align-items="center" />
		<Image {...override("image")} />
		<QuarklycommunityKitMobileSidePanel {...override("quarklycommunityKitMobileSidePanel")}>
			<Override {...override("quarklycommunityKitMobileSidePanelOverride")} />
			<Override {...override("quarklycommunityKitMobileSidePanelOverride1")} />
			<Box {...override("box")}>
				<Link {...override("link")} />
				<Link {...override("link2")} />
				<Link {...override("link3")} />
			</Box>
		</QuarklycommunityKitMobileSidePanel>
		{children}
	</Section>;
};

Object.assign(Header, { ...Section,
	defaultProps,
	overrides
});
export default Header;